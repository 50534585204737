import audience from '../images/audience.png';
import speaker2 from '../images/speaker2.jpeg';
import techbrawl1 from '../images/techbrawl1.jpeg';
import placement1 from '../images/placement1.jpeg';
import codehunt from '../images/codehunt.jpg';
import IC from '../images/IC.jpg';
import gsoc from '../images/gsoc_webinar.png';
import peripherithon from '../images/Peripherithon.jpg';
import aarambh23 from '../images/aarambh23.jpg';
import ieeeday23 from '../images/ieeeday23.jpg';
import blog23 from '../images/blog23.jpg';

export const events2023 = [
  {
    name: 'Blog Writing 2023',
    desc: 'Circular Economy X Blog Writing Competition',
    image: blog23,
  },
  {
    name: 'IEEE Day 2023',
    desc: 'Celebration of IEEE Day with our Honoured Guests',
    image: ieeeday23,
  },
  {
    name: 'Aarambh 2023',
    desc: 'An Ice breaker Session for our Freshers',
    image: aarambh23,
  },
];

export const events = [
  {
    name: 'Peripherathon 2023',
    desc: 'A AI powered IoT hackathon',
    image: peripherithon,
  },
  {
    name: 'codeHunt 2023',
    desc: 'A mix of treasure hunt and a CP contest along with the guidance of ICPC finalist Vivek Gupta',
    image: codehunt,
  },
  {
    name: 'Innovation Cup 2023',
    desc: 'A hardware hackathon',
    image: IC,
  },
  {
    name: 'GSOC Webinar 2023',
    desc: 'A webinar on how to get selected for GSOC',
    image: gsoc,
  },
  {
    name: 'Tackling Placements 2023',
    desc: "A seminar by our college's alumni about placements and higher studies",
    image: placement1,
  },
  {
    name: 'Webinar on Blockchain',
    desc: 'A webinar by instustry experts to get insights on the current usage of blockchain',
    image: speaker2,
  },
  {
    name: 'Aarambh 2022',
    desc: 'A fun ice breaker session for FEs',
    image: audience,
  },
  {
    name: 'Techbrawl 2022',
    desc: 'A tech competition for FEs',
    image: techbrawl1,
  },
];
